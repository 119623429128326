import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderTwo } from 'secondstep-components'
import { getBackgroundColor } from './helper'

export const Background = styled(Box).attrs(
  ({ dataTestId, isMindYetiProduct, isSelaProduct, isOstProduct }) => {
    const backgroundColor = getBackgroundColor(
      isSelaProduct,
      isMindYetiProduct,
      isOstProduct,
      themeGet,
    )

    return {
      'data-testid': dataTestId,
      backgroundColor,
    }
  },
)`
  margin-bottom: 1.25rem;
  border-radius: 0.75rem;
  background: ${({ backgroundColor }) => backgroundColor};
  width: 60.875rem;
`

export const Body = styled(Box).attrs(({ isSelaProduct }) => {
  if (!isSelaProduct) {
    return {
      headerColor: themeGet('colors.secondStepBlue'),
      headerFontSize: '1.4375rem',
      headerLineHeight: '1.875rem',
    }
  }
})`
  margin-left: 0.5rem;
  border-radius: 0.625rem;
  background: ${themeGet('colors.white')};

  h2 {
    margin: 0;
    color: ${({ headerColor }) => headerColor};
    font-size: ${({ headerFontSize }) => headerFontSize};
    line-height: ${({ headerLineHeight }) => headerLineHeight};
  }

  ${themeGet('breakpoints.mobile')} {
    h2 {
      width: 17.5rem;
    }
  }
`

export const Content = styled.div.attrs(({ isHighSchool }) => ({
  fontWeight: isHighSchool ? 700 : 400,
  subheaderFontFamily: isHighSchool
    ? themeGet('font.komet')
    : themeGet('font.molde.semiBold'),
}))`
  padding: 0.625rem;

  .program-subheader {
    font-family: ${({ subheaderFontFamily }) => subheaderFontFamily};
    font-weight: ${({ fontWeight }) => fontWeight};
  }

  & > h3 {
    color: ${themeGet('colors.secondStepBlue')};
    margin: 0 0.625rem 0.375rem;
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-family: ${themeGet('font.molde.semiBold')};
  }
`

export const HeaderBox = styled(Box).attrs(({ isHsInstancedView }) => ({
  flexDirection: isHsInstancedView ? 'column' : 'row',
}))`
  flex-direction: ${({ flexDirection }) => flexDirection};
  row-gap: 1.25rem;
  justify-content: space-between;
  padding-bottom: 1.25rem;
  margin: 1.75rem 1.25rem 0;
  border-bottom-style: solid;
  // we override these styles for high school:
  border-bottom-width: ${themeGet('dashboard.border.width', '1.5px')};
  border-bottom-color: ${themeGet('dashboard.border.color', '#DDDDDD')};
  align-items: baseline;

  @media (max-width: 650px) {
    flex-direction: column;
    row-gap: 1.25rem;

    button {
      align-self: flex-start;
      margin: 0.4375rem 0 0.125rem 0;
    }
  }
`

export const HighSchoolProgramHeader = styled(HeaderTwo)`
  display: block;
  font-family: ${themeGet('font.komet')};
  font-weight: 800;
  // need to make these !important to override some more-specific styles above:
  font-size: 1.625rem !important;
  color: ${themeGet('colors.highschool.green2')} !important;
  letter-spacing: unset;
  margin-bottom: 0.375rem;
`

export const TileContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
  }
`
