import { FlagsComponentRouter } from 'flags'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: ['feature_LEARN-18123-filter-educator-profiles'],
  OnComponent,
  OffComponent,
})

export default FlagToggler
