import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isFlagOn } from 'utils/featureFlags'

const EducatorProfileContext = createContext({})

export const PreferenceTypes = {
  STUDENT_ACTIVITIES: 'StudentActivities',
  EDUCATOR_PRACTICES: 'EducatorPractices',
  SCHOOLWIDE_PRACTICES: 'SchoolwidePractices',
}

const FLAG_KEY = 'feature_LEARN-18123-filter-educator-profiles'
const ADMIN_LABEL = 'admin'

export function EducatorProfileCheckerProvider(props) {
  const { currentSite, children } = props
  const contextValue = useMemo(() => ({ currentSite }), [currentSite])

  return (
    <EducatorProfileContext.Provider value={contextValue}>
      {children}
    </EducatorProfileContext.Provider>
  )
}

EducatorProfileCheckerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  currentSite: PropTypes.shape({
    highSchoolSitePreferences: PropTypes.object,
    highSchoolEducatorPreferences: PropTypes.object,
    claims: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

export function useEducatorPreferences() {
  const { currentSite } = useContext(EducatorProfileContext)
  const { highSchoolSitePreferences, highSchoolEducatorPreferences, claims } =
    currentSite ?? {}

  const shouldCheckEducatorPreferences = isFlagOn([FLAG_KEY])
  const shouldShowAllLearningExperiences =
    !highSchoolEducatorPreferences?.teachesStudentActivities &&
    !highSchoolEducatorPreferences?.teachesEducatorActivities &&
    !highSchoolEducatorPreferences?.teachesSchoolwideActivities

  function checkEducatorPreferences(preference) {
    if (!shouldCheckEducatorPreferences || shouldShowAllLearningExperiences) {
      return true
    }

    switch (preference) {
      case PreferenceTypes.STUDENT_ACTIVITIES:
        return (
          highSchoolSitePreferences?.studentActivities &&
          highSchoolEducatorPreferences?.teachesStudentActivities
        )
      case PreferenceTypes.EDUCATOR_PRACTICES:
        return !!highSchoolEducatorPreferences?.teachesEducatorActivities
      case PreferenceTypes.SCHOOLWIDE_PRACTICES:
        const isAdmin = claims?.some(
          claim => claim.toLowerCase() === ADMIN_LABEL.toLowerCase(),
        )
        return (
          isAdmin &&
          !!highSchoolEducatorPreferences?.teachesSchoolwideActivities
        )
      default:
        return false
    }
  }

  return {
    checkEducatorPreferences,
    currentSite,
  }
}
