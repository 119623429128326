import React from 'react'

export function superscriptRegistered(title) {
  if (title.includes('®')) {
    const parts = title.split('®')
    return (
      <>
        {parts[0]}
        <sup>®</sup> {parts[1].trim()}
      </>
    )
  }
  return title
}
