export const getBackgroundColor = (
  isSelaProduct,
  isMindYetiProduct,
  isOstProduct,
  themeGet,
) => {
  if (isOstProduct) {
    return themeGet('colors.programCard.background.color')
  } else if (isMindYetiProduct) {
    return themeGet('colors.lightBlue')
  } else if (!isSelaProduct && !isMindYetiProduct) {
    return themeGet('colors.themePrimary')
  } else {
    return themeGet('colors.themeAccent')
  }
}
